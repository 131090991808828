import React from "react"
import { SafeArea } from "../SafeArea"
import {
  Root,
  Slice,
  Container,
  PrivacyContainer,
  Header,
  TextHeader,
  Subheader,
  TextContainer,
} from "./styled"

type PrivacyProps = {}

export const Privacy: React.FC<PrivacyProps> = props => {
  return (
    <Root flexWrap="wrap" flex={1}>
      <Slice />
      <SafeArea>
        <Container
          height="100%"
          flexWrap="wrap"
          justifyContent="left"
          alignItems="left"
        >
          <Header>
            <TextHeader>
              Politique de confidentialité de SparkThink de Slalom
            </TextHeader>
          </Header>
          <PrivacyContainer>
            <TextContainer>
              <p>
                La présente politique de confidentialité («{" "}
                <b>Politique de confidentialité de SparkThink</b> ») est publiée
                par Slalom, LLC, dba Slalom Consulting et ses filiales («{" "}
                <b>Slalom</b> », « <b>nous</b> », « <b>notre</b> » ou «{" "}
                <b>nos</b> ») en ce qui concerne l’outil de sondage SparkThink
                (« <b>SparkThink</b> »).
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                La présente politique (ainsi que notre{" "}
                <a href="https://www.slalom.com/fr-ca/politique-en-matiere-de-cookies/?lp=1">
                  Politique sur les témoins
                </a>{" "}
                et tout autre document auquel elle fait référence) définit les
                fondements sur lesquels nous traitons les renseignements
                personnels que nous collectons auprès de vous par le biais de
                SparkThink lorsque Slalom mène un sondage par le biais de
                SparkThink ou que vous fournissez à Slalom par le biais de
                SparkThink lorsque vous répondez à un sondage de Slalom.{" "}
                <b>
                  La présente politique <u>ne couvre</u> pas les renseignements
                  personnels que nous collectons hors ligne ou par le biais
                  d’autres sites en ligne (comme notre site Web{" "}
                  <a href="https://www.slalom.com">www.slalom.com</a>). Pour
                  obtenir des informations sur les pratiques de confidentialité
                  de nos clients lorsque nous menons un sondage pour le compte
                  d’un tiers, veuillez consulter les politiques de
                  confidentialité et les avis de l’entité qui vous a fourni le
                  lien de le sondage (si vous ne l’avez pas reçu directement de
                  Slalom), et/ou l’entité désignée comme étant l’entité qui mène
                  le sondage.
                </b>
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                <em>
                  Pour les résidents de l’Espace économique européen, du
                  Royaume-Uni ou de la Suisse (« EEE+ »), veuillez consulter nos
                  déclarations supplémentaires concernant la loi sur la
                  protection des données de l’EEE+ de la présente politique en
                  cliquant <a href="#eea">ici</a>.
                </em>
              </p>
              <p>
                <em>
                  Pour les résidents de la Californie, veuillez consulter notre
                  Politique de la loi californienne sur la protection de la vie
                  privée des consommateurs « à la collecte » en cliquant{" "}
                  <a href="#ccpa">ici</a>.
                </em>
              </p>
              <p>
                <em>
                  Pour les résidents du Mexique, veuillez consulter nos
                  déclarations supplémentaires concernant la loi sur la
                  protection des renseignements du Mexique de la présente
                  politique en cliquant <a href="#msdp">ici</a>.
                </em>
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                Veuillez lire attentivement ce qui suit pour comprendre notre
                position et nos pratiques concernant vos renseignements
                personnels et la façon dont nous les traiterons. En utilisant
                l’outil SparkThink, vous acceptez et consentez aux pratiques
                décrites dans la présente politique.
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                Si le sondage que vous recevez est mené par Slalom, vos données
                seront collectées et contrôlées par Slalom, LLC (une société à
                responsabilité limitée américaine organisée aux termes des lois
                de l’État du Colorado et située au 821 2nd Avenue, Suite 1900,
                Seattle, WA 98104) qui partagera ces données au sein du groupe
                Slalom et/ou avec une entreprise de Slalom à laquelle se
                rapporte le sondage SparkThink («{" "}
                <b>société Slalom concernée</b> »). Par exemple, si vous
                remplissez un sondage au Royaume-Uni, alors vos renseignements
                personnels peuvent être partagés avec Slalom Consulting Limited
                (une société constituée au Royaume-Uni) à ces fins. Dans ce cas,
                Slalom, LLC et la société Slalom concernée peuvent toutes deux
                être considérées comme des contrôleurs de vos données aux termes
                de la loi applicable (par exemple, le Règlement général sur la
                protection des données 2016/679 et sa législation nationale
                d’application et la Loi sur la protection des données 2018 [la «{" "}
                <b>législation sur la protection de la vie privée</b> »]). Nous
                et la société Slalom concernée pouvons également partager vos
                renseignements personnels avec nos autres filiales et au sein du
                groupe Slalom de temps à autre aux fins énoncées dans la
                présente Politique de confidentialité de SparkThink.
              </p>
            </TextContainer>
            <Subheader>Informations que nous collectons de vous</Subheader>
            <TextContainer>
              <p>
                Nous collectons et traitons les données suivantes vous
                concernant :
              </p>
              <ul>
                <li>
                  <b>Informations que vous nous donnez.</b> Ce sont des
                  informations à votre sujet que vous nous donnez en répondant à
                  un sondage de Slalom. Les informations que vous nous donnez
                  peuvent inclure votre nom, adresse, adresse courriel, numéro
                  de téléphone ainsi que d’autres informations que vous
                  choisissez de fournir. La fourniture de ces renseignements
                  personnels est volontaire. Toutefois, si vous ne fournissez
                  pas ces renseignements personnels, nous pourrions ne pas être
                  en mesure d’inclure vos réponses dans notre sondage ou de vous
                  fournir les informations ou les services que vous nous
                  demandez. Vous saurez quels renseignements personnels vous
                  fournissez parce que vous nous les soumettrez activement, et
                  les renseignements personnels varieront en fonction de du
                  sondage que vous effectuez à l’aide de l’outil SparkThink.
                </li>
                <li>
                  <b>Informations que nous collectons à votre sujet.</b> En plus
                  des renseignements personnels que vous fournissez activement,
                  pour que Slalom surveille et maintienne les performances de
                  l’outil SparkThink et pour analyser les tendances,
                  l’utilisation et les activités en relation avec les sites,
                  SparkThink peut collecter automatiquement, traiter et stocker
                  certaines informations provenant de vous :
                  <ul>
                    <li>
                      les informations techniques, y compris le domaine et le
                      nom d’hôte à partir desquels vous accédez à Internet,
                      l’adresse de protocole Internet (IP) utilisée pour
                      connecter votre ordinateur à Internet, le type et la
                      version du navigateur, le fuseau horaire et le paramètre
                      de pays, les types et versions de plugiciels du
                      navigateur, le système d’exploitation et la plateforme ;
                      et
                    </li>
                    <li>
                      les informations sur votre visite, notamment l’adresse URL
                      complète, le parcours de navigation vers, à travers et
                      depuis notre site (y compris la date et l’heure), les
                      services que vous avez consultés ou recherchés, les temps
                      de réponse des pages, les erreurs de téléchargement, la
                      durée des visites de certaines pages, les informations sur
                      l’interaction avec la page (comme le défilement, les clics
                      et les survols de la souris) et les méthodes utilisées
                      pour quitter la page.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Informations que nous recevons d’autres sources.</b> Nous
                  travaillons en étroite collaboration avec des tiers (y
                  compris, par exemple, des services de marketing et des agences
                  de recrutement) qui peuvent nous fournir des informations
                  supplémentaires vous concernant.
                </li>
              </ul>
            </TextContainer>
            <Subheader>Témoins</Subheader>
            <TextContainer>
              <p>
                SparkThink utilise des témoins pour vous distinguer des autres
                utilisateurs de SparkThink. Cela nous aide à vous offrir une
                bonne expérience lorsque vous utilisez SparkThink et nous permet
                également de personnaliser et d’améliorer SparkThink. Pour les
                détails concernant les témoins que nous utilisons et les raisons
                pour lesquelles nous les utilisons, voir notre{" "}
                <a href="https://www.slalom.com/fr-ca/politique-en-matiere-de-cookies/?lp=1">
                  Politique sur les témoins
                </a>
                . Certains navigateurs Web comportent une fonction ou un
                paramètre de « Do Not Track » (« DNT ») que vous pouvez activer
                pour signaler votre préférence en matière de confidentialité
                afin que les données relatives à vos activités de navigation en
                ligne ne soient pas surveillées et collectées. Vous pouvez faire
                certains choix concernant les témoins et les technologies
                similaires pour communiquer votre préférence de ne pas être
                suivi en ligne dans notre{" "}
                <u>centre de préférences en matière de confidentialité</u>. Mais
                nous ne répondons pas aux signaux DNT.
              </p>
            </TextContainer>
            <Subheader>Utilisations faites des informations</Subheader>
            <TextContainer>
              <p>
                Nous utilisons les informations à votre sujet de la manière
                suivante :
              </p>
              <ul>
                <li>
                  <b>Informations que vous nous donnez.</b> Nous utiliserons ces
                  informations :
                  <ul>
                    <li>
                      pour remplir nos obligations découlant de toute entente
                      conclue entre vous et nous et pour vous fournir les
                      informations, produits et services que vous nous demandez
                      ;
                    </li>
                    <li>
                      pour vous fournir des informations sur d’autres biens et
                      services que nous proposons et qui sont similaires à ceux
                      que vous avez déjà achetés ou sur lesquels vous vous êtes
                      renseigné ;
                    </li>
                    <li>
                      pour vous fournir, ou permettre à des tiers sélectionnés
                      de vous fournir, des informations sur des biens ou des
                      services qui, selon nous, pourraient vous intéresser. Nous
                      communiquerons avec vous par voie électronique (courriel
                      ou messagerie texte) uniquement pour vous donner des
                      informations sur des biens et services similaires à ceux
                      qui ont fait l’objet d’une vente ou de négociations de
                      vente précédentes, ou lorsque vous y avez consenti, ou
                      lorsque nous pouvons légalement le faire.
                    </li>
                    <li>
                      pour vous informer des changements apportés à notre
                      service ; et
                    </li>
                    <li>
                      pour garantir que le contenu de SparkThink est présenté de
                      la manière la plus efficace pour vous et pour votre
                      ordinateur.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Informations que nous collectons à votre sujet.</b> Nous
                  utiliserons ces informations :
                  <ul>
                    <li>
                      pour administrer SparkThink et pour les opérations
                      internes, y compris le dépannage, l’analyse des données,
                      les tests, la recherche, les statistiques et les sondages
                      ;
                    </li>
                    <li>
                      pour garantir que le contenu de SparkThink est présenté de
                      la manière la plus efficace pour vous et pour votre
                      ordinateur ;
                    </li>
                    <li>
                      dans le cadre de nos efforts pour assurer la sécurité de
                      SparkThink ; et
                    </li>
                    <li>
                      pour vous faire, à vous et aux autres utilisateurs de
                      SparkThink, des suggestions et des recommandations
                      concernant des biens ou des services susceptibles de vous
                      intéresser.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Informations que nous recevons d’autres sources.</b> Nous
                  combinerons ces informations avec celles que vous nous donnez
                  et celles que nous collectons à votre sujet. Nous utiliserons
                  ces informations et les informations combinées aux fins
                  énoncées ci-dessus (selon les types d’information que nous
                  recevons).
                </li>
              </ul>
            </TextContainer>
            <Subheader>Divulgation de vos informations</Subheader>
            <TextContainer>
              <p>
                Vous convenez que nous avons le droit de partager vos
                informations personnelles avec :
              </p>
              <ul>
                <li>Tout membre de notre groupe, c’est-à-dire nos filiales.</li>
                <li>
                  Des tiers sélectionnés, notamment :
                  <ul>
                    <li>
                      les prestataires de services qui agissent en tant que
                      processeur des données de Slalom qui sont soumis à des
                      obligations contractuelles pour mettre en œuvre des
                      mesures de sécurité techniques et organisationnelles pour
                      sauvegarder les renseignements personnels et pour traiter
                      les renseignements personnels uniquement selon les
                      instructions. Ces prestataires de services tiers nous
                      aident à évaluer nos contrôles de sécurité de
                      l’information, à maintenir SparkThink, à analyser le
                      trafic de SparkThink et à nous fournir, ainsi qu’aux
                      utilisateurs de SparkThink, des services et des
                      informations ; et
                    </li>
                    <li>
                      les prestataires d’analyses et de moteurs de recherche qui
                      nous aident à améliorer et à optimiser SparkThink.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Nous divulguerons également vos informations personnelles à des
                tiers :
              </p>
              <ul>
                <li>
                  Dans le cas où nous vendons ou achetons une entreprise ou des
                  actifs, auquel cas nous divulguerons vos renseignements
                  personnels au vendeur ou à l’acheteur potentiel de cette
                  entreprise ou de ces actifs.
                </li>
                <li>
                  Si Slalom, LLC ou ses filiales, ou la quasi-totalité des
                  actifs de l’une des entités précitées, est acquise par un
                  tiers, auquel cas les renseignements personnels qu’elle
                  détient feront partie des actifs transférés.
                </li>
                <li>
                  Si nous sommes dans l’obligation de divulguer ou de partager
                  vos renseignements personnels afin de nous conformer à une
                  obligation légale, ou afin de faire respecter ou d’appliquer
                  d’autres ententes ; ou pour protéger les droits, la propriété
                  ou la sécurité de Slalom, LLC ou de ses filiales, de nos
                  employés, de nos clients ou d’autres personnes.
                </li>
              </ul>
            </TextContainer>
            <Subheader>Divulgations transfrontalières</Subheader>
            <TextContainer>
              <p>
                Vos renseignements personnels peuvent être divulgués à des
                employés, des représentants ou d’autres tiers opérant en dehors
                du pays où vous êtes établi, qui travaillent pour nous ou nos
                filiales dans d’autres pays, y compris les États-Unis et dans
                d’autres pays où nous opérons, ou qui sont engagés par nous ou
                nos filiales dans d’autres pays, ainsi qu’à nos prestataires de
                services qui peuvent être situés en dehors du pays où vous êtes
                établi, y compris les États-Unis et dans d’autres pays où nous
                opérons. Nous notons que ces personnes et tiers peuvent
                également stocker, transférer ou accéder à des renseignements
                personnels en dehors du pays où vous êtes établi.
              </p>
              <p>
                En divulguant vos renseignements personnels à l’étranger, nous
                prendrons les mesures raisonnables dans les circonstances pour
                exiger que les destinataires étrangers protègent vos
                renseignements personnels conformément aux lois applicables.
              </p>
            </TextContainer>
            <Subheader>Ressources et plaintes</Subheader>
            <TextContainer>
              <p>
                Vos renseignements personnels peuvent être divulgués à des
                employés, des représentants ou d’autres tiers opérant en dehors
                du pays où vous êtes établi, qui travaillent pour nous ou nos
                filiales dans d’autres pays, y compris les États-Unis et dans
                d’autres pays où nous opérons, ou qui sont engagés par nous ou
                nos filiales dans d’autres pays, ainsi qu’à nos prestataires de
                services qui peuvent être situés en dehors du pays où vous êtes
                établi, y compris les États-Unis et dans d’autres pays où nous
                opérons. Nous notons que ces personnes et tiers peuvent
                également stocker, transférer ou accéder à des renseignements
                personnels en dehors du pays où vous êtes établi.
              </p>
              <p>
                En divulguant vos renseignements personnels à l’étranger, nous
                prendrons les mesures raisonnables dans les circonstances pour
                exiger que les destinataires étrangers protègent vos
                renseignements personnels conformément aux lois applicables.
              </p>
              <p>
                Les questions, commentaires ou demandes concernant la présente
                Politique de confidentialité de SparkThink sont les bienvenus et
                doivent être adressés par courriel à{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a> ou
                par courrier à Slalom, LLC, 821 2nd Avenue, Suite 1900, Seattle,
                WA 98104. De même, si vous avez des plaintes à formuler
                concernant nos pratiques de gestion de la confidentialité, vous
                pouvez communiquer avec nous aux mêmes coordonnées. Vous pouvez
                également avoir le droit de déposer votre plainte auprès de
                l’organisme de réglementation de la protection de la vie privée
                compétent dans votre juridiction.
              </p>
            </TextContainer>
            <Subheader>
              Modifications de la Politique de confidentialité de SparkThink
            </Subheader>
            <TextContainer>
              <p>
                De temps à autres, nous pouvons apporter des modifications à la
                présente politique, y compris des modifications importantes.
                Nous vous invitons à consulter la présente politique chaque fois
                que vous utilisez SparkThink afin de prendre connaissance de
                toute modification.
              </p>
              <p>Date d’entrée en vigueur : 1er août 2022</p>
            </TextContainer>
            <Subheader id="eea">
              Déclarations supplémentaires concernant la loi sur la protection
              des données dans l’EEE+
            </Subheader>
            <TextContainer>
              <p>
                Slalom, LLC fournit aux résidents de l’EEE+ les informations
                suivantes en complément de la Politique de confidentialité de
                SparkThink.
              </p>
              <p>
                Contrôleur de données : Le responsable du traitement des données
                est Slalom dont les coordonnées sont précisées ci-dessus.
              </p>
              <p>
                <u>Bases juridiques</u> du traitement aux termes du droit de
                l’Union européenne et du Royaume-Uni : Les bases légales du
                traitement de vos renseignements personnels par Slalom sont :
                (i) le contrat sur l’utilisation de SparkThink conclu avec vous
                (art.  6(1)b) de la GDPR) pour les renseignements personnels
                fournis activement par vous par le biais de SparkThink ; (ii)
                nos intérêts légitimes (art.  6(1)f) de la GDPR) pour les
                renseignements personnels collectés passivement par le biais de
                SparkThink qui sont les suivants : surveiller et maintenir les
                performances de SparkThink et analyser les tendances,
                l’utilisation et les activités en rapport avec SparkThink ;
                (iii) nos intérêts légitimes (art.   6(1)f) de la GDPR) pour le
                transfert de vos renseignements personnels au sein du groupe de
                sociétés qui sont les suivants : à des fins administratives et
                de support internes (l’accès est limité aux collègues ayant
                besoin de savoir) ; (iv) le respect d’une obligation légale à
                laquelle Slalom est soumise (art.  6(1)c) de la GDPR) pour la
                transmission de renseignements personnels aux organismes chargés
                de l’application de la loi, aux autorités gouvernementales, aux
                conseillers juridiques et aux consultants externes ou aux
                intérêts légitimes, comme l’exercice ou la défense de
                revendications légales ; (v) nos intérêts légitimes (art.
                 6(1)b) de la GDPR) pour le transfert de vos renseignements
                personnels dans le cadre d’un transfert de tout ou partie de
                notre organisation ou de nos actifs qui sont les suivants : la
                transition ordonnée de tout ou partie de notre activité ; et
                (vi) nos intérêts légitimes pour protéger et défendre nos droits
                ou nos biens ou ceux de tiers, y compris l’application des
                ententes, des politiques et des conditions d’utilisation, et en
                cas d’urgence, y compris pour protéger la sécurité de nos
                employés ou de toute personne qui sont les suivants : protéger
                les biens, les droits et la sécurité de toute personne et
                prévenir la fraude.
              </p>
              <p>
                <u>Destinataires dans des pays tiers :</u> Les renseignements
                personnels que nous collectons ou recevons à votre sujet peuvent
                être transférés et traités par des destinataires situés à
                l’intérieur ou à l’extérieur de l’EEE+ et qui ne sont pas
                reconnus, du point de vue du droit de l’EEE+, comme assurant un
                niveau adéquat de protection des données. Ces tiers sont
                généralement des prestataires d’hébergement en nuage et de
                services Internet, des prestataires de systèmes d’exploitation
                et de plateformes, et certains{" "}
                <a href="https://www.slalom.com/platforms">
                  partenaires technologiques
                </a>
                . Lorsque vous interagissez avec l’outil SparkThink ou que vous
                communiquez avec Slalom, LLC, vous transférez des renseignements
                personnels aux États-Unis, et Slalom, LLC peut transférer vos
                renseignements personnels au Royaume-Uni, en Australie, au
                Japon, au Canada, en Allemagne, en Suisse, en Irlande, au
                Mexique et en Nouvelle-Zélande. Les transferts de la Suisse vers
                le Royaume-Uni, l’Allemagne, le Canada, la Nouvelle-Zélande et
                l’Irlande ne nécessitent pas de mesures de protection
                supplémentaires. Les transferts de la Suisse vers l’Australie,
                le Japon, le Mexique et les États-Unis reposent sur votre
                consentement. Dans la mesure où vos renseignements personnels
                sont transférés vers des pays qui n’offrent pas un niveau
                adéquat de protection des renseignements du point de vue de la
                législation de l’Union européenne ou du Royaume-Uni, nous
                baserons le transfert respectif sur des garanties appropriées
                (art. 46 de la GDPR/UK GDPR), comme les clauses types de
                protection des renseignements adoptées par la Commission
                européenne. Vous pouvez demander une copie de ces garanties
                appropriées en communiquant avec nous comme indiqué dans la
                section Ressources et plaintes.
              </p>
              <p>
                <u>Période de conservation des données :</u> Vos renseignements
                personnels seront stockés aussi longtemps que nécessaire pour
                vous fournir les services demandés, ce qui, dans la plupart des
                cas, ne dépasse pas cinq ans. Lorsque Slalom n’aura plus besoin
                d’utiliser vos renseignements personnels pour se conformer à ses
                obligations contractuelles ou légales, nous les supprimerons de
                nos systèmes et de nos dossiers et/ou prendrons des mesures pour
                les rendre correctement anonymes afin que vous ne puissiez plus
                être identifié à partir de ces données, à moins que nous n’ayons
                besoin de conserver vos informations, y compris vos
                renseignements personnels, à des fins fiscales, d’audit et de
                conformité juridique pendant une période ultérieure prescrite
                par la loi, ou si nous en avons besoin pour conserver des
                preuves dans le cadre de la prescription.
              </p>
              <p>Droits des personnes concernées : </p>
              <p>
                Aux termes de la loi applicable, vous pouvez avoir les droits
                suivants en ce qui concerne vos renseignements personnels.
                Ceux-ci peuvent être limités aux termes de la législation
                applicable sur la protection des données.
              </p>
              <p>
                (i) Droit de nous demander l’accès aux renseignements personnels
                : Vous avez le droit de nous confirmer si vos informations
                personnelles sont traitées, et si c’est le cas, de demander
                l’accès à ces informations personnelles, y compris les
                catégories d’informations personnelles traitées, la finalité du
                traitement et les destinataires ou catégories de destinataires.
                Vous avez le droit d’obtenir une copie des renseignements
                personnels en cours de traitement. Toutefois, il ne s’agit pas
                d’un droit absolu et les intérêts d’autres personnes peuvent
                restreindre votre droit d’accès.
              </p>
              <p>
                (ii) Droit de rectification : Vous avez le droit d’exiger que
                nous procédions à la rectification des informations personnelles
                inexactes ou incomplètes vous concernant. En fonction des
                finalités du traitement, vous pouvez avoir le droit de faire
                compléter des renseignements personnels incomplets, y compris en
                fournissant une déclaration complémentaire.
              </p>
              <p>
                (iii) Droit à l’effacement de vos renseignements personnels
                (droit à l’oubli) : Vous avez le droit de nous demander
                d’effacer les renseignements personnels vous concernant.
              </p>
              <p>
                (iv) Droit à la restriction du traitement : Dans des
                circonstances limitées, vous avez le droit de nous demander de
                restreindre le traitement de vos renseignements personnels.
              </p>
              <p>
                (v) Droit à la portabilité des données : Vous pouvez avoir le
                droit de recevoir les renseignements personnels vous concernant,
                que vous nous avez fournis, dans un format structuré, couramment
                utilisé et lisible par machine et vous pouvez avoir le droit de
                transmettre ces renseignements personnels à une autre entité
                sans entrave de notre part.
              </p>
              <p>
                <b>
                  (vi) Droit d’opposition : Dans certaines circonstances, vous
                  pouvez avoir le droit de vous opposer, pour des raisons liées
                  à votre situation particulière, à tout moment au traitement de
                  vos renseignements personnels, y compris le profilage, par
                  nous et nous pouvons être tenus de ne plus traiter vos
                  renseignements personnels.
                </b>
              </p>
              <p>
                <b>
                  En outre, si vos renseignements personnels sont traités à des
                  fins de commercialisation directe, vous avez le droit de vous
                  opposer à tout moment au traitement des renseignements
                  personnels vous concernant pour une telle commercialisation,
                  ce qui inclut le profilage dans la mesure où il est lié à
                  cette commercialisation directe. Dans ce cas, nous ne
                  traiterons plus vos renseignements personnels à ces fins.
                </b>
                <br />
                En fonction de votre lieu de résidence, vous pouvez également
                avoir le droit de déposer une plainte auprès d’une autorité de
                surveillance. Si vous avez déclaré votre consentement pour toute
                activité de traitement de renseignements personnels, vous pouvez
                retirer ce consentement à tout moment avec effet futur. Un tel
                retrait n’affectera pas la légalité du traitement antérieur au
                retrait du consentement. Vous pouvez exercer vos droits en
                communiquant avec nous comme indiqué dans la section Ressources
                et plaintes.
              </p>
              <p>
                <u>Défaut de fournir des renseignements personnels : </u>
                Lorsque la loi nous oblige à collecter vos renseignements
                personnels ou que nous devons le faire aux termes d’un contrat
                que nous avons conclu avec vous, et que vous ne fournissez pas
                ces renseignements personnels lorsque nous vous les demandons,
                il se peut que nous ne soyons pas en mesure d’exécuter le
                contrat que nous avons conclu ou que nous essayons de conclure
                avec vous. Cela peut s’appliquer lorsque vous ne fournissez pas
                les renseignements personnels dont nous avons besoin pour
                fournir les services que vous nous avez demandés. Dans ce cas,
                nous pourrions être amenés à annuler la prestation des services
                en question, auquel cas nous vous en informerons.
              </p>
              <p>
                Pour votre information :
                <ul>
                  <li>
                    Les demandes relatives à nos activités en Europe sont
                    généralement traitées par Slalom Consulting Limited, une
                    société constituée et enregistrée en Angleterre et au Pays
                    de Galles sous le numéro 08874662, dont le siège social est
                    situé au 2 London Bridge, 2nd Floor East, Londres, SE1 9RA,
                    Royaume-Uni ;
                  </li>
                  <li>
                    Les demandes relatives à nos activités en Europe sont
                    généralement traitées par Slalom Consulting Limited, une
                    société constituée et enregistrée en Angleterre et au Pays
                    de Galles sous le numéro 08874662, dont le siège social est
                    situé au 2 London Bridge, 2nd Floor East, Londres, SE1 9RA,
                    Royaume-Uni ;
                  </li>
                  <li>
                    Les demandes relatives à nos activités aux États-Unis et au
                    Mexique sont généralement traitées par Slalom, LLC, une
                    société à responsabilité limitée organisée selon les lois de
                    l’État du Colorado et située au 821 2nd Avenue, Suite 1900,
                    Seattle, WA 98104, États-Unis ;
                  </li>
                  <li>
                    Les demandes relatives à nos activités au Japon sont
                    généralement traitées par Slalom Japan Co., Ltd., une
                    société par actions japonaise dont le siège social est
                    Shiroyama Trust Tower, 21F 4 Chrome 3-1 Toranomon, Minato-ku
                    Tokyo 105-6021, Japon ;
                  </li>
                  <li>
                    Les demandes relatives à notre activité en Australie sont
                    généralement traitées par Slalom Australia Pty Ltd, une
                    société constituée et enregistrée en Australie sous le
                    numéro 635 861 004, dont le siège social est situé au Level
                    17, 100 Queen Street, Melbourne Victoria 30000, Australie ;
                  </li>
                  <li>
                    Les demandes relatives à nos activités en Nouvelle-Zélande
                    sont généralement traitées par Slalom New Zealand Ltd, une
                    société constituée et enregistrée en Nouvelle-Zélande sous
                    le numéro 8244300, dont le siège social est situé au Level
                    22 Floor, PWC Tower, 15 Customs Street West, Auckland
                    Central, Auckland 1010, Nouvelle-Zélande ;
                  </li>
                </ul>
                sauf avis contraire de notre part.
              </p>
            </TextContainer>
            <Subheader>
              Déclarations supplémentaires concernant la loi sur la protection
              des données du Japon
            </Subheader>
            <TextContainer>
              <p>
                <b>1. Coordonnées des entités Slalom concernées</b>
              </p>
              <p>
                L’adresse et le nom des représentants de la société des entités
                Slalom qui traitent régulièrement les informations personnelles
                des individus au Japon sont les suivants :
              </p>
              <p>
                Slalom, LLC
                <br />
                821 2nd Avenue, Suite 1900, Seattle, WA 98104 <br />
                Représentant de la société : Christopher Burger, Vice-président,
                Sécurité de l’information et gouvernance{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                Slalom Japan Co., Ltd.
                <br />
                7F Atago Green Hills Mori Tower, 2 Chome 5-1 Atago, Minato-ku
                Tokyo 105-6207, Japan
                <br />
                Représentant de la société : Ko Asami, Directeur général Japon,{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                Pour les détails concernant d’autres entités, veuillez
                communiquer avec nous par courriel à l’adresse{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a> ou
                par courrier à l’adresse Slalom Consulting, Privacy Office, 821
                2nd Avenue, Suite 1900, Seattle, WA 98104.
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                <b>
                  2. Utilisation conjointe des renseignements personnels au sein
                  du groupe Slalom
                </b>
              </p>
              <p>
                Nous partagerons vos renseignements personnels décrits dans la
                présente politique avec les entités Slalom (veuillez consulter
                la liste des entités Slalom ci-dessous). Ces entités Slalom
                utiliseront les renseignements personnels aux fins énoncées dans
                la section « Utilisations faites des informations ». L’entité
                qui est responsable de la gestion des renseignements personnels
                est Slalom K.K. spécifiée ci-dessus.
              </p>
              <p>
                <b>Liste des entités Slalom</b>
                <br />
                Slalom, LLC
                <br />
                Slalom Consulting, ULC
                <br />
                Slalom Consulting Ltd.
                <br />
                Slalom Australia Pty Ltd.
                <br />
                Slalom Netherlands B.V.
                <br />
                Slalom GmbH
                <br />
                Slalom Limited
                <br />
                Slalom New Zealand Limited
                <br />
                Slalom Switzerland GmbH
                <br />
                Slalom Technology and Management Solutions Limited
                <br />
                Slalom Consulting Mexico S.R.L. de C.V.
              </p>
            </TextContainer>
            <Subheader id="ccpa">
              Politique de la loi californienne sur la protection de la vie
              privée des consommateurs « à la collecte »
            </Subheader>
            <TextContainer>
              <p>
                Dans cet avis à la collecte en ligne de la Politique de la loi
                californienne sur la protection de la vie privée des
                consommateurs (« <b>CCPA</b> »), nous abordons les exigences de
                divulgation prévues par le Code civil de Californie, section
                1798.100, et les règlements du procureur général de Californie
                au moment de la collecte en ligne ou avant celle-ci, en ce qui
                concerne les informations collectées par Slalom auprès des
                résidents californiens lorsque ces derniers répondent à un
                sondage sur l’outil SparkThink menée par Slalom.{" "}
                <b>
                  Cet avis à la collecte en ligne de la CCPA <u>ne couvre</u>{" "}
                  pas les renseignements personnels que nous collectons hors
                  ligne ou par le biais d’autres sites en ligne (comme notre
                  site Web, <a href="https://slalom.com">www.slalom.com</a>).
                  Pour obtenir des renseignements sur les pratiques de
                  confidentialité de nos clients lorsque nous menons un sondage
                  pour le compte d’un tiers, veuillez consulter les politiques
                  de confidentialité et les avis de l’entité qui vous a fourni
                  le lien du sondage (si vous ne l’avez pas reçu directement de
                  Slalom), et/ou l’entité désignée comme étant l’entité qui mène
                  le sondage.
                </b>
              </p>
              <p>
                Nous collectons les catégories suivantes d’informations
                personnelles sur les consommateurs et aux fins professionnelles
                ou commerciales suivantes :
              </p>
              <p>
                Les informations que vous fournissez lorsque vous répondez à un
                sondage au moyen de l’outil de sondage SparkThink. Les
                informations que vous nous donnez peuvent comprendre votre nom,
                votre adresse électronique et votre numéro de téléphone, ainsi
                que d’autres informations que vous choisissez de fournir. La
                fourniture de ces renseignements personnels est volontaire.
                Toutefois, si vous ne fournissez pas ces renseignements
                personnels, nous ne seront pas en mesure d’inclure vos réponses
                dans notre sondage ou de vous fournir les informations ou les
                services que vous nous demandez, le cas échéant. Vous saurez
                quels renseignements personnels vous fournissez parce que vous
                nous les soumettrez activement, et les renseignements personnels
                varieront en fonction de du sondage que vous effectuez à l’aide
                de l’outil SparkThink. Nous utilisons les informations que vous
                nous fournissez activement :
                <ul>
                  <li>
                    pour remplir nos obligations découlant de toute entente
                    conclue entre vous et nous et pour vous fournir les
                    informations, produits et services que vous nous demandez ;
                  </li>
                  <li>
                    pour vous fournir des informations sur d’autres biens et
                    services que nous proposons et qui sont similaires à ceux
                    que vous avez déjà achetés ou sur lesquels vous vous êtes
                    renseigné ;
                  </li>
                  <li>
                    pour vous fournir, ou permettre à des tiers sélectionnés de
                    vous fournir, des informations sur des biens ou des services
                    qui, selon nous, pourraient vous intéresser. Nous
                    communiquerons avec vous par voie électronique (courriel ou
                    messagerie texte) que pour vous donner des informations sur
                    des biens et services similaires à ceux qui ont fait l’objet
                    d’une vente ou de négociations de vente précédentes, ou
                    lorsque vous y avez consenti, ou lorsque nous pouvons
                    légalement le faire.
                  </li>
                  <li>
                    pour vous informer des changements apportés à notre service
                    ; et
                  </li>
                  <li>
                    pour garantir que le contenu de SparkThink est présenté de
                    la manière la plus efficace pour vous et pour votre
                    ordinateur.
                  </li>
                </ul>
              </p>
              <p>
                Les consommateurs souffrant d’un handicap visuel peuvent être en
                mesure d’utiliser un lecteur d’écran ou un autre outil de
                synthèse vocale ou de texte en braille pour consulter le contenu
                de cet avis. Nous ne vendons pas vos informations personnelles
                et ne les partageons pas à des fins de publicité comportementale
                inter-contexte.
              </p>
              <p>
                Vos informations personnelles seront conservées aussi longtemps
                que nécessaire pour vous fournir les services demandés, ce qui,
                dans la plupart des cas, ne dépasse pas cinq ans. Lorsque Slalom
                n’aura plus besoin d’utiliser vos renseignements personnels pour
                se conformer à ses obligations contractuelles ou légales, nous
                les supprimerons de nos systèmes et de nos dossiers et/ou
                prendrons des mesures pour les rendre correctement anonymes afin
                que vous ne puissiez plus être identifié à partir de ces
                données, à moins que nous n’ayons besoin de conserver vos
                informations, y compris vos renseignements personnels, à des
                fins fiscales, d’audit et de conformité juridique pendant une
                période ultérieure prescrite par la loi, ou si nous en avons
                besoin pour conserver des preuves dans le cadre de la
                prescription.
              </p>
              <p>
                La politique de confidentialité de la CCPA est disponible à
                l’adresse suivante :{" "}
                <a href="https://www.slalom.com/fr-ca/privacy-policy?lp=1">
                  https://www.slalom.com/fr-ca/privacy-policy?lp=1
                </a>
                .
              </p>
            </TextContainer>
            <Subheader id="msdp">
              Déclarations supplémentaires concernant la loi sur la protection
              des renseignements du Mexique
            </Subheader>
            <TextContainer>
              <p>
                <b>1. Vos droits relatifs aux informations personnelles</b>
              </p>
              <p>
                <ul>
                  <li>
                    <u>Droit d’accès</u>
                    <br />
                    Vous pouvez avoir le droit de nous confirmer si vos
                    informations personnelles sont traitées, et si c’est le cas,
                    de demander l’accès à ces renseignements personnels, y
                    compris les catégories d’informations personnelles traitées
                    et la finalité du traitement.
                  </li>
                  <li>
                    <u>Droit de rectification</u>
                    <br />
                    Vous pouvez avoir le droit d’obtenir de notre part la
                    rectification d’informations personnelles inexactes ou
                    incomplètes vous concernant.
                  </li>
                  <li>
                    <u>Droit d’annulation</u>
                    <br />
                    Vous pouvez avoir le droit de nous demander d’annuler des
                    informations personnelles vous concernant et nous évaluerons
                    cette demande conformément aux lois en vigueur.
                  </li>
                  <li>
                    <u>Droit d’opposition</u>
                    <br />
                    Dans certaines circonstances, vous pouvez avoir le droit de
                    vous opposer à notre traitement de vos renseignements
                    personnels et nous évaluerons cette demande conformément aux
                    lois applicables.
                  </li>
                  <li>
                    <u>Droit de révoquer/supprimer votre consentement.</u>
                    <br />
                    Vous avez le droit de retirer le consentement que vous nous
                    avez accordé pour le traitement de vos informations
                    personnelles et nous évaluerons cette demande conformément
                    aux lois en vigueur.
                  </li>
                </ul>
              </p>
              <p>
                Pour exercer l’un des droits mentionnés ci-dessus, ou pour
                soumettre des questions ou des plaintes, veuillez communiquer
                avec nous par courriel à l’adresse électronique suivante :{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                Vos demandes seront évaluées conformément aux lois applicables.
                Sur demande, nous pouvons vous fournir les éléments suivants :
                (i) les informations que nous devons recevoir de votre part pour
                vous identifier ainsi que les documents que vous devez joindre à
                votre demande ; (ii) les délais dans lesquels vous recevrez une
                réponse de notre part concernant votre demande ; (iii) la façon
                dont vous pouvez déposer votre demande, y compris les modèles ou
                mécanismes disponibles (le cas échéant) ; et (iv) le format
                médiatique dans lequel nous vous fournirons l’information (p.
                ex., par courriel, sur CD, etc.).
              </p>
            </TextContainer>
          </PrivacyContainer>
        </Container>
      </SafeArea>
    </Root>
  )
}
