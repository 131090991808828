import React from "react"
import { Flex, Box } from "reflexbox/styled-components"
import styled from "styled-components"

// ============================================================
// Shared styled components for Privacy Policy content
// ============================================================

// Components
const Root = styled(Flex)`
  width: 100%;
  color: #fff;
  position: relative;
`

const Slice = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  background: ${props => props.theme.colors.primary[500]};
`

const Container = styled(Flex)`
  flex-direction: column;
  padding: 100px 0 0 0;

  @media screen and (min-width: 40em) {
    flex-direction: column;
  }

  @media screen and (min-width: 52em) {
    flex-direction: column;
  }

  @media screen and (min-width: 64em) {
    flex-direction: row;
    padding: 100px 0 0 0;
  }
`

const PrivacyContainer = styled(Box)`
  background-color: #fff;
  color: ${props => props.theme.colors.text};
  font-size: 1.2em;
  line-height: 1.6em;
  padding: 0.5rem;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  z-index: 100;

  @media screen and (min-width: 52em) {
    padding: 2rem;
  }

  p {
    line-height: 1.4;
    font-weight: 300;
  }

  ul li {
    line-height: 1.4;
    font-weight: 300;
  }

  em {
    font-style: italic;
  }
`

const Header = styled(Box)`
  width: 100%;
  margin: 1rem 0 2rem;

  @media screen and (min-width: 52em) {
    text-align: center;
    margin: 2rem 0;
  }
`

const TextHeader = styled.h1`
  font-weight: bold;
  font-size: 3rem;
  line-height: 1.2;

  @media screen and (min-width: 52em) {
    font-size: 3rem;
  }
`

const Subheader = styled(Box)`
  padding: 1rem;
  padding-bottom: 0;
  font-weight: bold;
  font-size: 1.25em;

  @media screen and (min-width: 52em) {
    padding: 1rem 1.5rem 0.25rem;
  }
`

const TextContainer = styled(Box)`
  padding: 1rem;

  @media screen and (min-width: 52em) {
    padding: 1rem 1.5rem;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1rem;
  }

  ul li:not(:last-child) {
    margin-bottom: 1rem;
  }

  ul li ul {
    margin: 1rem;
  }

  & b {
    font-weight: bold;
  }

  & ul {
    margin-left: 30px;
    list-style: disc outside;

    & li ul {
      list-style: circle outside;
    }
  }
`

export {
  Root,
  Slice,
  Container,
  PrivacyContainer,
  Header,
  TextHeader,
  Subheader,
  TextContainer,
}
